import React from 'react'
import Section from '../../Section'

const Contact = () => {
  return (
    <Section id="contact">
      <h1>contact me here</h1>
      <a href="https://forms.gle/WvRYYVQvCeFXBNyW7">contact me</a>
    </Section>
  )
}

export default Contact