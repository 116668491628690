import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Section from '../../Section'
import './style.css'

const Skillsets = () => {
  const data = useStaticQuery(graphql`
    {
      allSkillsJson {
        edges {
          node {
            name
          }
        }
      }
    }
  `)

  const skills = data.allSkillsJson.edges

  return (
    <Section id="skillset">
      <h1>my skillsets</h1>
      <ul className="skillsetlist">
        {
          skills.map(
            skill => (
              <li className="skill">
                {skill.node.name}
              </li>
            )
          )
        }
      </ul>
    </Section>
  )
}

export default Skillsets