import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section";
import About from "../components/Main/About";
import Skillsets from "../components/Main/Skillsets";
import Projects from "../components/Main/Projects";
import Contact from "../components/Main/Contact";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <Section id="main">
        <h1>Hi, I'm Henry</h1>
        <p>I work in Dallas, TX as a front-end developer.</p>
        <p>When I'm not coding, I'm probably making food. Or thinking about food. Or eating it...</p>
        {/* <p>Feel free to read my blog <a href="#blog">here</a> (it's new btw).</p> */}
      </Section>
      <About />
      <Skillsets/>
      <Projects/>
      <Contact/>
    </Layout>
  )
}
export default IndexPage
