import React from 'react'
import Section from '../../Section'

const About = () => {
  return (
    <Section id="about">
      <h1>about me</h1>
      <p>I'm a native born Texan.</p>
      <p>I can ride a horse, though I'm much better at not riding them.</p>
      <p>I love me some JavaScript. Usually when I'm not writing code for work, I'm writing code for myself.</p>
      <p>My philiosophy about learning: it's a life long pursuit and I'll stop trying to learn once I die. Not before.</p>
      <p>Dirty code is avoidable, and clean code is the goal.</p>
    </Section>
  )
}

export default About