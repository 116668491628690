import React from "react"
import styled from "styled-components"

export const ProjectWrapper = styled.div`
  display: flex;
`

export const ProjectList = styled.span`
  display: inline-block;
  border-right: 2px solid black;
  padding-right: 1rem;
  margin-right: 1rem;
  vertical-align: top;
`

export const ListTab = styled.div`
  color: blue;
  cursor: pointer;
  font-size: 1.62671rem;
  font-weight: bold;
  margin-bottom: 1.45rem;
  text-rendering: optimizeLegibility;
  white-space: nowrap;

  & :hover {
    text-decoration: underline;
  }
`

export const PreviewWrapper = styled.span`
  display: flex;
`

export const PreviewImage = styled.span`
  width: 100%;
  min-width: 240px;

  @media (min-width: 0px) and (max-width: 768px) {
    display: none;
  }
`

export const PreviewDescription = styled.span`
  padding-right: 1rem;
`