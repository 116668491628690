import React, { useState } from 'react'
import { graphql, Link, StaticQuery } from "gatsby";
import Section from '../../Section';
import ProjectPreview from "./Preview";
import {
  ListTab,
  ProjectList,
  ProjectWrapper
} from './styled';

const AllProjects = ({ projects }) => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0)
  const selectedProject = projects[selectedProjectIndex].node
  const title = selectedProject.title
  const description = selectedProject.description
  const slug = selectedProject.slug
  const imageData = selectedProject.image.childImageSharp.fluid

  return (
    <Section id="projects">
      <h1>sample of my projects</h1>
      <ProjectWrapper>
        <ProjectList>
          { projects.map(({ node: project }, idx) => (
              <ListTab onClick={() => setSelectedProjectIndex(idx)}>{project.title}</ListTab>
            ))
          }
        </ProjectList>
        <ProjectPreview
          title={title}
          description={description}
          slug={slug}
          imageData={imageData}
        />
      </ProjectWrapper>
    </Section>
  )
}

const AllProjectsWrapper = () => (
  <StaticQuery
    query={graphql`
      {
        allProjectsJson {
          edges {
            node{
              title
              slug
              description
              image {
                childImageSharp {
                  fluid{
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <AllProjects projects={data.allProjectsJson.edges} />
    )}
  />
)

export default AllProjectsWrapper

// export default AllProjects