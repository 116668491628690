import React from 'react'
import {
  StyledSection
} from './styled.js'
import './section.css'

class Section extends React.Component {
  render() {
    return (
      <StyledSection {...this.props} className={`section ${this.props.className || ''}`}>
        {this.props.children}
      </StyledSection>
    )
  }
}

export default Section