import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import {
  PreviewWrapper,
  PreviewImage,
  PreviewDescription
} from './styled';

const imageStyles = {
  maxHeight: '400px',
  objectFit: 'contain',
}

const ProjectPreview = ({ title, description, slug, imageData }) => (
  <PreviewWrapper>
    <PreviewDescription>
      <h2>
        <Link to={`/${slug}/`}>{title}</Link>
      </h2>
      <p>{description}</p>
      <p>
        <Link to={`/${slug}/`}>View this project &rarr;</Link>
      </p>
    </PreviewDescription>
    <PreviewImage>
      <Link to={`/${slug}/`}>
        <Image
          alt={title}
          fluid={imageData}
          style={imageStyles}
          imgStyle={imageStyles}
          />
      </Link>
    </PreviewImage>
  </PreviewWrapper>
);

export default ProjectPreview;